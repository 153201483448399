<template>
  <div class="request-invoice">
    <div class="stepbox">
      <div class="col toolbar-title">
        对账开票
        <el-button @click=" $router.go(-1);" size="small" class="back_btn">
          <img src="../../assets/back.png" alt="">
        </el-button>
      </div>
      <el-steps :active="1" finish-status="success" simple style="margin-top: 20px">
        <el-step title="1、选择开票账单，点击申请开票"></el-step>
        <el-step title="2、确认开票信息和地址"></el-step>
        <el-step title="3、提交开票申请成功"></el-step>
      </el-steps>
    </div>
    <div class="requestInvoice">
      <div style="padding-top: 1px;">
        <div class="cont">
          <div class="left">
            <span style="font-size: 20px;">开票信息</span>
            <el-form style="margin-top: 15px;">
              <el-form-item label="发票类型" style="margin-bottom: 12px;">
                <el-select v-model="invoice_state" placeholder="请选择状态">
                  <el-option label="增值税普通发票" :value="1"></el-option>
                  <el-option label="增值税专用发票" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="height:232px;padding:18px 18px 4px 18px;background:#eee">
              <p>发票抬头：{{ InvoiceData && InvoiceData.receipt_title }}</p>
              <p>企业税号：{{ InvoiceData && InvoiceData.tax_no }}</p>
              <p v-if="invoice_state == '2'">开户银行：{{ InvoiceData && InvoiceData.bank_name }}</p>
              <p v-if="invoice_state == '2'">开户账号：{{ InvoiceData && InvoiceData.bank_account }}</p>
              <p v-if="invoice_state == '2'">注册地址：{{ InvoiceData && InvoiceData.reg_addr }}</p>
              <p v-if="invoice_state == '2'">注册电话：{{ InvoiceData && InvoiceData.reg_tel }}</p>
            </div>
          </div>
          <div class="right">
            <span style="font-size: 20px;">寄送地址</span>
            <el-form style="margin-top: 15px;">
              <el-form-item label="选择地址">
                <el-select v-model="addressId" placeholder="请选择状态" @change="addressChange">
                  <el-option v-for="item in addressList" :key="item.id" :label="item.label"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="height:232px; padding:18px 18px 4px 18px;background:#eee">
              <p>收件人姓名：{{ address && address.receive_name }}</p>
              <p>手机号：{{ address && address.receive_phone }}</p>
              <p>详细地址：{{ address &&
          address.province + address.city + address.county + (address.town || '') + address.receive_addr }}
              </p>
            </div>
          </div>
          <div class="btn">
            <h2>开票金额：<span style="color: red;">{{ price }}</span></h2>
            <div>
              <el-button @click="showPartFn" type="danger">修改开票信息和地址</el-button>
              <el-button style="width: 167px;" @click="submits" type="primary">确认并提交</el-button>
            </div>

          </div>
        </div>
      </div>
      <div class="tables">
        <div class="table_tit">开票账单：</div>
        <el-table :header-cell-style="{
          textAlign: 'center',
          backgroundColor: 'rgba(230, 236, 247, 1)',
        }" style="width: 100%; text-align: center" ref="orderinvoiceList" :data="orderinvoiceList" border>
          <el-table-column prop="trade_sn" label="账单年月">
            <template slot-scope="{row}">{{ row.sn | sn_filter }}</template>
          </el-table-column>
          <el-table-column prop="goods_total" label="商品总金额"></el-table-column>
          <el-table-column prop="shop_freight_price" label="运费总金额"></el-table-column>
          <el-table-column prop="statement_total" label="账单总金额" />
        </el-table>
        <!-- <div class="pages">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage4" :page-size="paramsData.page_size" :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout" background :total="total">
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as API_goods from "@/api/goods";
export default {
  data () {
    return {
      invoice_state: 1,//增值税发票类型 1:增值税普通发票 2:增值税专用发票
      InvoiceData: {},//发票抬头信息
      addressId: '',
      addressList: [],//地址列表
      address: {},//地址信息
      price: 0,
      orderinvoiceList: [],
      serviceinvoiceList: [],
      platFormInvoiceList: [],
      paramsData: {
        page_no: 1,
        page_size: 9999,
        invoice_state: 1,//开票状态 0未开票 1开票中 2已开票
        step: 2,//步骤
        invoice_check: 1,//判断订单是否全部退款
      },
      total: 0,
      currentPage4: 1,
    };
  },
  filters: {
    sn_filter: value => {
      let str = value.split('')
      str.splice(4, 0, '年')
      let newValue = str.join('')
      newValue = newValue + '月'
      return newValue
    }
  },
  mounted () {
    this.price = this.$route.query.price;
    this.getDefaultShopReceiptAddress();
    this.getInvoiceTitleDetail();
    this.getWaitReceiptOrderList();

  },
  methods: {
    handleSizeChange (size) {
      this.paramsData.page_size = size;

      this.getWaitReceiptOrderList();

    },
    handleCurrentChange (page) {
      this.paramsData.page_no = page;

      this.getWaitReceiptOrderList();

    },
    showPartFn () {
      this.$router.push({ path: "/finance/Invoicemanger/Invoicemangerdetail" });
    },
    getInvoiceTitleDetail () {
      // 搜索发票抬头信息
      API_goods.getInvoiceTitleDetail().then((res) => {
        this.InvoiceData = res.shop_receipt
        this.invoice_state = res.shop_receipt.receipt_type == "VATOSPECIAL" ? 2 : 1
        this.address = res.address ? res.address : ''
        this.addressId = res.address?.id ? res.address.id : ''
      });
    },
    addressChange (id) {//地址切换
      this.address = this.addressList.find(item => item.id == id)
    },
    getDefaultShopReceiptAddress () {
      API_goods.getShopReceiptAddress().then(res => {
        res.forEach(item => {
          item.label = `${item.receive_name} ${item.receive_phone}`
        })
        this.addressList = res;
      });
      API_goods.getDefaultShopReceiptAddress().then((res) => {
        for (let j in res) {
          this.$set(this.InvoiceData, j, res[j]);
        }
      });
    },
    getWaitReceiptOrderList () {
      API_goods.getWaitReceiptOrderList(this.paramsData).then((res) => {
        this.total = res.data_total;
        this.orderinvoiceList = res.data;
      });
    },
    getPlatFormFreight () {
      API_goods.getPlatFormFreight(this.paramsData).then(response => {
        this.platFormInvoiceList = response.data;
        this.total = response.data_total;
      })
    },
    submits () {
      if (this.invoice_state == '2' && this.InvoiceData.bank_name == '') {
        this.$message.error('开票信息不完整，请完善开票信息')
        return
      } else if (!this.address.receive_name) {
        this.$message.error('开票信息不完整，请完善开票信息')
        return
      } else if (!this.addressId) {
        this.$message.error('请选择寄送地址')
        return
      }
      API_goods.getWaitReceiptOrderListaddHistory({
        is_all: 0,
        step: 2,//当前步骤
        shop_receipt_address_id: this.addressId,//地址id
        //发票类型
        invoice_type: this.invoice_state == '1' ? 'VATORDINARY' : 'VATOSPECIAL',
      }).then((res) => {
        this.$message.success("提交成功");
        this.$router.push({ path: "/finance/reconciliationInvoices" });
      });

    },
  },
};
</script>
<style lang="scss" scoped>
.stepbox {
  background: #fff;
  border-radius: 4px;
  padding-top: 20px;

  .toolbar-title {
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    padding-left: 15px;
    margin-right: 12px;
  }

  .toolbar-title::before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #1A43A9;
    position: absolute;
    top: 26%;
    left: 0px;
    transform: translate(0, -50%);
  }

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;
    float: right;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

/deep/ .el-step.is-simple:not(:last-of-type) .el-step__title {
  max-width: 64%;
}

.requestInvoice {
  &>div {
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }

  .cont {
    margin-top: 10px;
    display: -webkit-flex;

    .left {
      width: 30%;
      margin-right: 2%;
    }

    .right {
      width: 30%;
      margin-right: 2%;
    }

    .btn {
      text-align: center;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .tables {
    margin-top: 20px;
    padding: 20px;

    .table_tit {
      font-size: 18px;
      color: #666;
      margin-bottom: 20px;
    }

    .pages {
      margin-top: 20px;
    }

    .btn {
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
